  @tailwind base;
  @tailwind components;
  @tailwind utilities;
html,
body,
:root {
  margin: 0;  
  padding: 0;  
  box-sizing: border-box;
  height: 100%;
}
  @layer base {
    :root {
      --background: 0 0% 100%;
      --foreground: 222.2 84% 4.9%;

      --card: 0 0% 100%;
      --card-foreground: 222.2 84% 4.9%;

      --popover: 0 0% 100%;
      --popover-foreground: 222.2 84% 4.9%;

      --primary: 248 90% 60%;
      --primary-foreground: 210 40% 98%;

      --secondary: 210 40% 96.1%;
      --secondary-foreground: 222.2 47.4% 11.2%;

      --muted: 210 40% 96.1%;
      --muted-foreground: 215.4 16.3% 46.9%;

      --accent: 210 40% 96.1%;
      --accent-foreground: 222.2 47.4% 11.2%;

      --destructive: 0 84.2% 60.2%;
      --destructive-foreground: 210 40% 98%;

      --border: 214.3 31.8% 91.4%;
      --input: 214.3 31.8% 91.4%;
      --ring: 222.2 84% 4.9%;

      --radius: 0.5rem;
    }

    .dark {
      --background: 222.2 84% 4.9%;
      --foreground: 210 40% 98%;

      --card: 222.2 84% 4.9%;
      --card-foreground: 210 40% 98%;

      --popover: 222.2 84% 4.9%;
      --popover-foreground: 210 40% 98%;

      --primary: 210 40% 98%;
      --primary-foreground: 222.2 47.4% 11.2%;

      --secondary: 217.2 32.6% 17.5%;
      --secondary-foreground: 210 40% 98%;

      --muted: 217.2 32.6% 17.5%;
      --muted-foreground: 215 20.2% 65.1%;

      --accent: 217.2 32.6% 17.5%;
      --accent-foreground: 210 40% 98%;

      --destructive: 0 62.8% 30.6%;
      --destructive-foreground: 210 40% 98%;

      --border: 217.2 32.6% 17.5%;
      --input: 217.2 32.6% 17.5%;
      --ring: 212.7 26.8% 83.9%;
    }
  }

  @layer base {
    * {
      @apply border-border;
    }
    body {
      @apply bg-gradient-to-r from-deep-blue to-blue text-foreground;
    }
  }

.nav-link {
  @apply after:w-6 after:h-1 after:absolute after:left-3  after:-bottom-2 after:rounded-full after:bg-transparent after:hover:bg-[#fff] hover:text-[#b936f5]  after:transition-all after:duration-300 relative;
}

.bgc-color {
  @apply bg-gradient-to-r from-deep-blue to-blue;
}

.btn {
  @apply h-11 px-8 bg-gradient-to-r from-[#b936f5] to-[#f1006c] rounded  hover:bg-gradient-to-l hover:from-[#b936f5] hover:to-[#f1006c] outline-none flex items-center justify-center cursor-pointer;
}

.section-heading {
  @apply after:content-[''] after:flex after:justify-center after:mt-[0.2rem] after:w-[4rem] after:mx-auto after:h-[0.2rem] after:bg-[#06c96c];
}
.section-heading1 {
  @apply after:content-[''] after:flex after:justify-start after:mt-[0.2rem] after:w-[4rem]  after:h-[0.2rem] after:bg-red;
}
.swiper-nav-btns {
  display: flex;
  justify-content: center;
}

.swiper-nav-btns button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: white;
  outline: none;
  color: rgba(12, 65, 117, 0.7);
  margin: 0 0.3rem;
  transition: all 0.5s;
}
.nav-link {
  @apply after:w-6 after:h-1 after:absolute after:left-3  after:-bottom-2 after:rounded-full after:bg-transparent after:hover:bg-[#fff] hover:text-[#b936f5]  after:transition-all after:duration-300 relative;
}

.bgc-color {
  @apply bg-gradient-to-r from-deep-blue to-blue;
}

.btn {
  @apply h-11 px-8 bg-gradient-to-r from-[#b936f5] to-[#f1006c] rounded  hover:bg-gradient-to-l hover:from-[#b936f5] hover:to-[#f1006c] outline-none flex items-center justify-center cursor-pointer;
}

.section-heading {
  @apply after:content-[''] after:flex after:justify-center after:mt-[0.2rem] after:w-[4rem] after:mx-auto after:h-[0.2rem] after:bg-[#06c96c];
}
.faq {
  @apply  bg-gray-200 active:bg-gray-100 rounded-lg border m-4 p-6 text-gray-700 leading-relaxed
}
.faq.active {
background-color: white;
box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1),0 3px 6px rgba(0, 0, 0, 0.1);
}
.faq.active::before,
.faq.active::after
{
content: '\213A';
transform: rotateY(180deg);
font-size: 7rem;
color: #2ecc71;
position: absolute;
opacity: 0.2;
top: 20px;
left: 20px;
z-index: 0;
}
.faq.active::after {
  color: #2498db;
  top: -20px;
  left: -30px;
}
.faq-title {
  margin: 0 20px 0 0;
}
.faq-text {
  display: none;
  margin: 10px 0 0;
}
.faq.active .faq-text {
  display: block;
}
.faq-toggle {
  display: none;
}
.faq.active .faq-toggle {
  display: block;
  background-color: #9fa4a8;
  cursor: pointer;
}
.faq.active  .faq-chev-down {
  display: none;
}
.faq-chev-down {
  display: block;
  font-size: 1.5rem;
}

.mobilenav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 290px;
  transform: translateX(-100%); /* Start off-screen */
  transition: transform 0.3s ease-in-out;
  z-index: 50;
}

.nav-visible {
  transform: translateX(0); /* Slide in */
}

